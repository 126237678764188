<template>
  <div class="d-flex" :style="{ flexDirection: message.from_me ? 'row-reverse' : 'row' }" style="width: 100%;">
    <div class="d-flex flex-column message">
      <div v-if="message.quoted_messages" class="message quoted_message d-flex flex-column">
        <div style="margin-bottom: 8px;">
          <span v-if="message.quoted_name !== true">Вы:</span>
          <span v-else>Собеседник:</span>
        </div>
        <span v-html="message.quoted_messages"/>
      </div>
      <div class="message-body">
        <document_message v-if="message.type_messages === 'document'" :message="message" />
        <image_message v-else-if="message.type_messages === 'image'" :message="message" />
        <audio_message v-else-if="message.type_messages === 'audio'" :message="message" />
        <vcard_message v-else-if="message.type_messages === 'vcard'" :message="message" />
        <text_message v-else :message="message" />
      </div>
      <div class="d-flex flex-row justify-content-end">
        <span class="sent-time">
          {{ message.time_sending }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import text_message from "@/components/chat_new/chat/messages/text_message";
import document_message from "@/components/chat_new/chat/messages/document_message";
import image_message from "@/components/chat_new/chat/messages/image_message";
import audio_message from "@/components/chat_new/chat/messages/audio_message";
import vcard_message from "@/components/chat_new/chat/messages/vcard_message";


export default {
  name: "message_template",
  components: { text_message, document_message, image_message, audio_message, vcard_message },
  props: {
    message: Object,
  },
}
</script>

<style scoped>
  .message {
    max-width: 90vw;
    width: fit-content;

    margin-bottom: 12px;
    margin-top: 12px;
    margin-left: 16px;
    margin-right: 16px;
    padding: 16px;

    word-wrap: anywhere;

    border-radius: 15px;
    background-color: whitesmoke;
  }

  .quoted_message {
    background-color: lightgray;
    margin: 0 0 16px 0;
  }

  .sent-time {
    margin-left: 24px;
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.5)
  }
</style>
<template>
  <div @click="setInstance" style="cursor: pointer;"  class="card">
    <div class="card-body">
      <h5 class="card-title d-flex" style="font-weight: 500; font-size: 24px;">
        <span
            v-if="instance.phone === this.$store.getters.getSelectInstance"
            style="color: green; font-weight: bold;" class="mr-2"
        >✓</span>
        <span>{{ instance.name }}</span>
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">{{ instance.phone }}</h6>
      <div class="d-flex">
        <span>Статус:</span>
        <p class="card-text ml-2" :style="{color: isInstanceOK ? '#48bb78bd' : '#f77676'}">{{ translatedStatus }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "instance_card",
  props: {
    instance: Object,
  },
  computed: {
    isInstanceOK() {
      return this.instance.last_status === 'authorized';
    },
    translatedStatus() {
      const translations = {
        authorized: 'Авторизован',
        notAuthorized: 'Не авторизован',
        blocked: 'Аккаунт забанен',
        sleepMode: 'Спящий режим',
      }

      return translations[this.instance.last_status] || this.instance.last_status;
    },
  },
  methods: {
    setInstance: function () {
      if (this.instance.phone === this.$store.getters.getSelectInstance) {
        return;
      }
      this.$emit('exitFromChat');
      this.$store.dispatch('resetChat')
      this.$store.dispatch('setSelectInstance', this.instance.phone)
    },
  },
}
</script>

<style scoped>

</style>
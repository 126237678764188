import axios from "axios";
import { getCurrentTime } from "@/util/main"

const state = {
  listOpenedWS: {},
  currentWS: undefined,
  paginatorInfoMessages: {},
  messages: {
    instance_id: undefined,
    chat_id: undefined,

    content: [],

    last_page: 1,
    id_first_messages: undefined,
    has_next: false
  },
  fakeMessages: [],
  forward: {
    id: undefined,
    text: undefined
  },
  operator: {
    name: '',
    date: ''
  }
}
const getters = {
  getMessages(state) {
    return state.messages.content
  },
  getPaginatorInfoMessages(state) {
    return state.messages
  },
  getListOpenedWS(state) {
    return state.listOpenedWS
  },
  getWS: (state) => (name_ws) => {
    // eslint-disable-next-line no-prototype-builtins
    return state.listOpenedWS.hasOwnProperty(name_ws)
  },
  getCurrentWS(state) {
    return state.currentWS
  },
  getFakeMessages(state) {
    return state.fakeMessages
  },
  getForward(state) {
    return state.forward
  },
  getOperator(state) {
    return state.operator
  }
}
const mutations = {
  setCurrentWS(state, data) {
    state.currentWS = data
  },
  // Скелет нового сообщения. Чтобы не ждать ответа для появления сообщения
  addSkeletonMessage(state, data) {
    let skeleton = {
      "from_me": true,
      "messages_id": null,
      "isforwarded": 0,
      "body_messages": data.message,
      "quoted_messages": "",
      "quoted_name": false,
      "read": false,
      "time_reading": null,
      "time_sending": getCurrentTime(),
      "type_messages": "chat"
    }
    state.messages.content.push(skeleton)
  },
  addTopMessage(state, data) {
    data.forEach(element => {
      let messages = state.messages.content;
      // Если есть прототипы сообщений - удаляем
      let messagesWithoutSkeleton = messages.filter(
          msg => msg.messages_id !== null
      )
      messagesWithoutSkeleton.push(element)
      state.messages.content = messagesWithoutSkeleton
    })
  },
  addDownMessage(state, data) {
    data.forEach(element => {
      state.messages.content.unshift(element);
    })
  },
  resetMessage(state) {
    state.messages.content = []
    state.messages.last_page = 1
  },
  setInfoMessages(state, data) {
    state.messages.instance_id = data.instance_id
    state.messages.chat_id = data.chat_id

    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('last_page')) {
      state.messages.id_first_messages = data.id_first_messages
      state.messages.has_next = data.has_next
      state.messages.last_page = data.last_page
    }

  },
  editLastPage(state, data) {
    state.messages.last_page = data
  },
  addNewWS(state, data) {
    state.listOpenedWS[data.key] = data.value;
  },
  addRemoveWS(state, data) {
    delete state.listOpenedWS[data.key];
  },
  setPaginatorInfoMessages(state, data) {
    state.paginatorInfoMessages = data
  },
  setFakeMessages(state, data) {
    state.fakeMessages.push(data)
  },
  removeFakeMessages(state, data) {
    console.log('call')

    state.fakeMessages.forEach(obj => {
      if (obj.chat_id === data.chat_id && data.type_messages === 'document') state.fakeMessages = []

      console.log('obj', obj)
      if (obj.chat_id === data.chat_id && obj.chat_id === data.chat_id) {
        obj.messages = obj.messages.replace(/(<(\/?[^>]+)>)/g, '')
        // obj.messages = obj.messages.replace('\n', '')

        if (obj.messages.toLowerCase().includes(data.messages.toLowerCase())) {
          let index = state.fakeMessages.indexOf(obj)
          state.fakeMessages.splice(index, 1);
          return true
        }
      }
    })
  },
  setDataRead(state, data) {
    let message = state.messages.content.find(message => message.messages_id === data.messages_id)
    if (message) {
      message.time_reading = data.time_reading
    }
  },
  setForward(state, data) {
    state.forward = data
  },
  setOperator(state, data) {
    state.operator = data
  }
}
const actions = {
  controlWS(context, data) {
    // instance_id, redis
    let WS = new WebSocket(data.redis + "/instance/" + data.instance_id + "/?token=" + localStorage.getItem('token'))
    if (context.getters.getCurrentWS) {
      let ws = context.getters.getCurrentWS;
      ws.close();
    }

    context.commit('setCurrentWS', WS)

    return WS
  },
  getPackMessages(context, data) {
    // first messages
    let dataServer = {
      type: 'messages.get',
      instance_id: data.instance_id,
      last_number: data.last_number,
      chat_id: data.chat_id,
    }

    data.ws.send(JSON.stringify(dataServer))
  },
  getInfoMessages(context, data) {
    let formData = new FormData();
    formData.append('id_messages', data.id)

    axios({
      method: 'post',
      url: process.env.VUE_APP_URL + 'api/v2/getMessageInfo',
      data: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }).then(result => {
      console.log('result1result111', result)
      context.commit('setOperator', {
        'data': result.data['operator_info']
      })
    }).catch(response => (console.log('error uploading', response)))

  }
}

export default {
  state, getters, mutations, actions
}

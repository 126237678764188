import emojisData from "@/assets/emojisData"
import store from "@/store";

export function cssSelectRemove(StyleName) {
  let all = document.getElementsByClassName(StyleName);
  while (all.length > 0) {
    all[0].classList.remove(StyleName)
  }

}

export function addCssFilter(id) {
  cssSelectRemove('selectCategory')
  document.getElementById(id).classList.add('selectCategory')
}

export function formatTimestamp(timestamp) {
  let date = new Date(timestamp * 1000);
  let hours = date.getHours();
  let minutes = "0" + date.getMinutes();

  let year = date.getFullYear()
  let month = '0' + (date.getMonth() + 1)
  let day = "0" + date.getDate()
  let formattedDate = day.substr(-2) + '.' + month.substr(-2) + '.' + year
  let formattedTime = hours + ':' + minutes.substr(-2)

  return formattedTime + ' ' + formattedDate
}

const emojiImage = `
    <img 
        class="emoji wa" 
        data-is-emoji="true" 
        src="[imageUrl]" 
        style="
          display: inline-block; 
          vertical-align: top; width: 40px; height: 40px
        " 
        alt="[emojiAlt]" 
        data-plain-text="[emojiAlt]"
    >
`.replaceAll('\n', '').replaceAll('\r', '')

export function generatorEmojiHtml(emojiUrl, alt) {
  return emojiImage
      .replaceAll('[emojiAlt]', alt)
      .replace('[imageUrl]', emojiUrl);
}

export function getCurrentTime() {
  return new Date().toLocaleString("ru", {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).replaceAll('.', '-')
}


export function getEmojisData() {
  if (getEmojisData.cache) {
    return getEmojisData.cache;
  }

  let jsonData = {};
  for (const [filename, unicode] of Object.entries(emojisData)) {
    let path = `/emojis/${filename}`
    jsonData[path] = decodeURIComponent(escape(unicode));
  }
  getEmojisData.cache = jsonData;
  return jsonData;
}

export const EMOJI_REGEXP = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug


export function socketOnMessage(store, event, outputObject = {}) {
  let data = JSON.parse(event['data']);
  let type = data['type']

  console.log('socket.onmessage', data)

  if (type === 'read') {
    store.commit('setDataRead', {'messages_id': data.messages_id, 'time_reading': data.reading_time})
    store.commit('updateChatData', {
      'chat_id': store.getters.getSelectChat.chat_id,
      'check_mark': true
    });
  }

  if (type === 'list_messages') {
    console.log('data.content', data.content)
    console.log('Пришло!!!')
    store.commit('setChatOpened');
    // пагинатор
    // eslint-disable-next-line no-prototype-builtins
    if (Object.prototype.hasOwnProperty.call(data, 'last_page')) {
      store.commit('addDownMessage', data.content)
      if (store.getters.getPaginatorInfoMessages.last_page <= 1){
        console.log(store.getters.getPaginatorInfoMessages.last_page)
        outputObject.scrollToBottom = true;
      }
    }

    // новые сообщения
    // eslint-disable-next-line no-prototype-builtins
    if (data.content.length === 1 &&
        !Object.prototype.hasOwnProperty.call(data, 'last_page') &&
        data.chat_id === store.getters.getSelectChat.chat_id &&
        data.instance_id === store.getters.getSelectChat.instance
    ) {
      if (store.getters.getSelectInstance === data.instance_id) {
        store.commit('updateChatData', {
          'chat_id': data.chat_id,
          'last_text_message_chat': data.content[0].body_messages,
          'last_time_update_chat': data.content[0].time_sending,
          'last_text_message_is_from_me': data.content[0].from_me,
          'new_timestamp': data.content[0].time_as_timestamp,
        })
      }

      // Добавить сообщение в чат
      store.commit('addTopMessage', data.content)
      document.getElementById('chat-box').scrollIntoView(false);
      console.log('data new', data)
    }

    if (data.content.length === 1 && data.content[0]['from_me'] === false) {
      if (store.getters.getSelectChat.chat_id !== data.chat_id || document.hidden) {
        let audio = new Audio('/sounds/message_notify.mp3');
        audio.play()
      }
    }
    if (data.content.length === 1 && store.getters.getSelectInstance === data.instance_id) {
      let setUnread = data.content[0]['from_me'] === false && store.getters.getSelectChat.chat_id !== data.chat_id
      setUnread = setUnread || document.hidden
      store.commit('updateLastChatMessage', {data, setUnread})
    }

    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('last_page')) {
      store.commit('setInfoMessages', {
        'id_first_messages': 1,
        'instance_id': data.instance_id,
        'chat_id': data.chat_id,
        'has_next': data.has_next,
        'last_page': data.last_page
      })
    }
  }

  return outputObject;
}

export function upWebSocketConnections() {
  let chat_list = store.getters.getChatListBase;
  let instance = store.getters.getSelectInstance;
  for (let i = 0; i < 200; i++) {
      const chat_data = chat_list[i]

      store.dispatch('controlWS', {
          'chat_id': chat_data.chat_id,
          'instance_id': instance,
          'redis': process.env.VUE_APP_WS
      }).then(socket => {
          socket.onmessage = event => socketOnMessage(store, event);
      })
  }
}
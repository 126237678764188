<template>
  <div class="container">
    <div class="row">
      <div style="margin-top: 15%" class="col-sm-9 col-md-7 col-lg-5 mx-auto">

        <div v-if="errorMessage" class="alert alert-danger" style="text-align: center" role="alert">
          {{ errorMessage }}
        </div>

        <div class="card card-signin my-5">
          <div class="card-body">
            <h5 class="card-title text-center">Авторизация</h5>

            <form v-on:submit.prevent class="form-signin" method="post">
              <div class="form-label-group">
                <input v-model="username" name="username" type="text" id="inputUsername" class="form-control"
                       placeholder="Имя пользователя"
                       required autofocus>
                <label for="inputUsername">Имя пользователя</label>
              </div>

              <div class="form-label-group">
                <input v-model="password" name="password" type="password" id="inputPassword" class="form-control"
                       placeholder="Пароль"
                       required>
                <label for="inputPassword">Пароль</label>
              </div>

              <button @click="login" class="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Войти
              </button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "auth",
  data() {
    return {
      'username': '',
      'password': '',
      'errorMessage': ''
    }
  },
  methods: {
    login() {
      let data = {
        'username': this.username,
        'password': this.password,
        'type': 'default'
      }

      this.$store.dispatch('login', data)
          .then(() => window.location.replace('/'))
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
            this.errorMessage = `Неверный логин или пароль`
          })

    },
  },
  created() {
    //Check session => redirect dashbord
    if (this.$store.getters.isLoggedIn) {
      this.$router.push('/')
    }


  }


}
</script>

<style scoped>

</style>
import VueJwtDecode from 'vue-jwt-decode'
import axios from 'axios';
import {socketOnMessage} from "../../util/main";


const state = {
  id: '',
  username: '',
  lastname: '',
  first_name: '',
  instance: [],
  is_staff: false,
  selection_instance_phone: undefined,
  sound_instance: []
}
const getters = {
  getUserID(state) {
    return state.id
  },
  getUserUsername(state) {
    return state.username
  },
  getUserLastname(state) {
    return state.lastname
  },
  getUserFirstName(state) {
    return state.first_name
  },
  getUserInstance(state) {
    return state.instance
  },
  getUserInstanceSelect(state) {
    return state.instance.find(instance => instance.phone === (state.selection_instance_phone))
  },
  getInstanceIDByPhone(state, phone) {
    let obj = (state.instance.find(instance => instance.phone === phone))
    return obj ? obj.id : null
  },
  getUserStaff(state) {
    return state.is_staff
  },
  getSelectInstance(state) {
    return state.selection_instance_phone
  },
  getActiveSoundInstance(state) {
    return state.sound_instance
  }
}
const mutations = {
  editState(state, data) {
    state.id = data.id
    state.username = data.username
    state.lastname = data.lastname
    state.first_name = data.first_name
    state.instance = data.instance
    state.is_staff = data.is_staff
    state.sound_instance = data.sound_instance
    return state
  },
  editSelectInstance(state, id) {
    return state.selection_instance_phone = id
  },
  editCountUnReadChat(state, data) {
    let instance = state.instance.find(instance => instance.id === data.id)

    if (instance) {
      instance.read_instance = data.count
    }
  }
}
const actions = {
  getUserInfo(context) {
    let userID;

    try {
      userID = VueJwtDecode.decode(localStorage.getItem('token'))['user_id']
    } catch (TypeError) {
      userID = null
    }
    axios({
      method: 'get',
      url: process.env.VUE_APP_URL + `api/v2/auth/getUserInfo/${userID}`
    }).then(result => {
      context.commit('editState', result.data)
      console.log('res', result.data)

      //set default Instance
      const firstInstance = context.getters.getUserInstance[0]
      context.dispatch('setSelectInstance', firstInstance['phone'])


      console.log('firstInstance', firstInstance)
    })
  },
  setSelectInstance(context, phone_instance) {
    context.dispatch('getChatsBase', {
      instance: phone_instance,
      status: context.getters.getSelectedStatus,
      page: '1'
    })
    context.commit('editSelectInstance', phone_instance)
    context.dispatch('controlWS', {
      'instance_id': context.getters.getSelectInstance,
      'redis': process.env.VUE_APP_WS
    }).then(socket => {
        socket.onmessage = event => socketOnMessage(context, event);
    })
  },
  editSound(context, data) {
    let formData = new FormData();

    formData.append('status', data.status)

    axios({
      method: 'put',
      url: process.env.VUE_APP_URL + `api/v2/updateSound/${data.id}/`,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data'}
    }).then(() => {
      if (data.status) {
        state.sound_instance.push(data.id)
      } else {
        let index = state.sound_instance.indexOf(data.id);
        if (index !== -1) {
          state.sound_instance.splice(index, 1);
        }
      }
    })
  }
}

export default {
  state, getters, mutations, actions
}

<template>
  <span
      class="d-flex align-items-end"
      style="flex-wrap: wrap;"
      v-html="replaceUtf8EmojiesToImages(message.body_messages)"
  />
</template>

<script>
import {getEmojisData, generatorEmojiHtml} from "@/util/main";

export default {
  name: "text_message",
  props: {
    message: Object,
  },
  data() {
    return {
      emojisData: getEmojisData(),
    }
  },
  methods: {
    replaceUtf8EmojiesToImages(text) {
      let tmp = []
      for (const [url, alt] of Object.entries(this.emojisData)) {
        if (tmp.includes(url)) {
          continue;
        }
        let html = generatorEmojiHtml(url, alt)
        text = text.split(alt).join(html);
        tmp.push(alt)
      }

      return text
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="d-flex flex-column" style="height: 100%;">
    <chat_messages/>
    <chat_input @newMessageSent="$emit('newMessageSent')"/>
  </div>
</template>

<script>
import chat_input from "@/components/chat_new/chat/chat_input";
import chat_messages from "@/components/chat_new/chat/chat_messages";

export default {
  name: "chatbox",
  components: {chat_input, chat_messages},
}
</script>

<style scoped>
</style>
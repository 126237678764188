<template>
  <div>
    <div class="chat_placeholder" />
    <div style="width: 100%; position: fixed; bottom: 0; background-color: white; padding: 0 8px 8px 8px; z-index: 0">
      <div class="input-group input-group-lg">
        <textarea
            class="form-control"
            :value="newMessageInput" @input="event => newMessageInput = event.target.value"
            style="height: 60px;"
        />
        <div class="input-group-append">
          <button
              @click="emojiMenuOpen = !emojiMenuOpen"
              class="btn btn-outline-secondary icon-box emoji-btn"
              :style="{backgroundColor: emojiMenuOpen ? '#6c757d' : 'rgb(255,255,255)'}"
              type="button"
          >
            <img class="icon" src="resources/emojies.png" alt="Эмодзи">
          </button>
          <button
              @click="sendMessages" class="btn btn-outline-secondary icon-box send-btn"
              :class="{ disabled: !isNewMessageCorrect }" type="button"
          >
            <img class="icon" src="resources/send_message.png" alt="Отправить">
          </button>
        </div>
      </div>
    </div>

    <div v-if="emojiMenuOpen" style="position: fixed; width: 100%; height: 100%; top: 0;">
      <div @click="emojiMenuOpen = false;" style="position: fixed; width: 100%; height: 100%; z-index: 1"/>
      <div class="card" style="position: fixed; width: 94vw; height: 70vh; top: 15vh; left: 3vw; z-index: 2;">
        <div class="card-body d-flex flex-column">
          <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 16px;">
            <span class="card-title" style="font-weight: 500; font-size: 24px;">Эмодзи</span>
            <button @click="emojiMenuOpen = false;">
              <img src="resources/menu_cancel.png" alt="Закрыть" style="width: 30px; height: 30px;">
            </button>
          </div>
          <div class="d-flex flex-row" style="gap: 8px; overflow-y: scroll; flex-wrap: wrap; height: 1px; flex-grow: 1;">
             <img
                v-for="(altEmoji, srcEmoji, key) in emojisData"
                :key="key"
                :data-emoji-index="key"
                style="display: inline-block; width: 55px; height: 55px;"
                :src="srcEmoji"
                :alt="altEmoji"
                @click="pasteEmojiAsText(altEmoji);"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getEmojisData} from "@/util/main";

export default {
  name: "chat_input",
  data() {
    return {
      emojiMenuOpen: false,
      emojisData: getEmojisData(),
      newMessageInput: '',
    }
  },
  computed: {
    isNewMessageCorrect() {
      return this.newMessageInput
          .replaceAll(' ', '')
          .replaceAll('\n', '')
          .length > 0
    },
  },
  methods: {
    pasteEmojiAsText(alt) {
      this.newMessageInput += alt;
    },
    pasteEmoji(alt) {

      let html = alt
      this.$refs.textareaEmoji.focus();

      let sel, range;
      if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          range.deleteContents();

          // Range.createContextualFragment() would be useful here but is
          // non-standard and not supported in all browsers (IE9, for one)
          let el = document.createElement("img");
          el.innerHTML = html;
          let frag = document.createDocumentFragment(), node, lastNode;
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node);
          }
          range.insertNode(frag);

          // Preserve the selection
          if (lastNode) {
            range = range.cloneRange();
            range.setStartAfter(lastNode);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
          }
        }
      } else if (document.selection && document.selection.type !== "Control") {
        // IE < 9
        document.selection.createRange().pasteHTML(html);
      }
    },
    sendMessages: function () {
      let message = this.newMessageInput;
      if (!this.isNewMessageCorrect) {
        return;
      }
      this.newMessageInput = '';
      message = message.trim();

      let data = {
        type: 'messages.send',
        chat_id: this.$store.getters.getSelectChat.chat_id,
        message: message,
        instance_id: this.$store.getters.getSelectChat.instance,
        operator_id: this.$store.getters.getUserID,
      }

      this.$store.commit('updateChatData', {
        'chat_id': this.$store.getters.getSelectChat.chat_id,
        'new_timestamp': Math.floor(Date.now() / 1000),
        'check_mark': false
      });

      if (this.$store.getters.getForward.id) {
        console.log('getForward', this.$store.getters.getForward)
        data['quotedMsgId'] = this.$store.getters.getForward.id

        this.$store.commit('setForward', {'id': undefined, 'text': undefined})
      }

      // Отправляем сообщение по вебсокету и добавляем скелет сообщения в чат
      this.$store.commit('addSkeletonMessage', data)
      this.$emit('newMessageSent')
      this.$store.getters.getCurrentWS.send(JSON.stringify(data));

      if (this.$store.getters.getInfoChat(this.$store.getters.getSelectChat.chat_id).name === 'Новый чат') {
        let a = this.$store.getters.getSelectChat.chat_id.split('@')[0]
        setTimeout(() => {
          window.location.href = `/chat/${this.$store.getters.getSelectChat.instance}/${a}/`
        }, 3900)
      }
    },
  }
}
</script>

<style scoped>
  .chat_placeholder {
    height: 68px;
  }

  .emoji-btn {
    box-shadow: none!important;
  }

  textarea {
    box-shadow: none!important;
  }

  .send-btn:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
    background-color: white;
  }

  .icon {
    width: 30px;
    height: 30px;
  }
</style>
<template>
  <div class="chat-box">
    <div style="margin: 32px 0; padding: 0 16px;">
      <button
        v-if="
          !$store.getters.isChatOpening &&
          $store.getters.getPaginatorInfoMessages &&
          $store.getters.getPaginatorInfoMessages.has_next
        "
        @click="nextPageMessages($store.getters.getSelectInstance, $store.getters.getPaginatorInfoMessages.chat_id)"
        type="button" class="btn btn-outline-primary btn-lg btn-block"
      >
        Загрузить еще
      </button>
    </div>
    <message_template v-for="message in $store.getters.getMessages" :key="message.id" :message="message" />
  </div>
</template>

<script>
import message_template from "@/components/chat_new/chat/messages/message_template";

export default {
  name: "chat_messages",
  components: { message_template },
  methods: {
    nextPageMessages(instance_id, chat_id) {
      this.$store.commit('editLastPage', this.$store.getters.getPaginatorInfoMessages.last_page)

      this.$store.dispatch('getPackMessages', {
        'chat_id': chat_id,
        'instance_id': instance_id,
        'last_number': this.$store.getters.getPaginatorInfoMessages.last_page,
        'ws': this.$store.getters.getCurrentWS
      })
    },
  }
}
</script>

<style scoped>
  .chat-box {
    flex-grow: 1;
  }

  .btn-outline-primary:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
    background-color: white;
    color: #007bff;
  }
</style>
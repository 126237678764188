<template>
  <div class="d-flex flex-column">
    <audio controls="controls" style="z-index: 0;"><source :src="downloadLink"></audio>
  </div>

</template>

<script>
export default {
  name: "text_message",
  props: {
    message: Object,
  },
  computed: {
    fileName() {
      return this.message.body_messages.split('/').at(-1);
    },
    downloadLink() {
      return process.env.VUE_APP_URL + this.message.body_messages;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div @click="$emit('click')" class="card d-flex align-items-center flex-row mt-3 contact" style="height: 110px;">
    <img :src="avatar" class="rounded-circle ml-2" alt="Аватар" style="height: 80px; width: 80px;">
    <div class="card-body">
      <div class="d-flex flex-row justify-content-between" style="margin-bottom: 16px;">
        <h5 class="card-title">{{ name }}</h5>
        <span style="text-align: end; flex: 0 0 20%; font-size: 0.8em;">{{ lastMessageDate }}</span>
      </div>
      <div class="d-flex justify-between flex-row second-row">
        <h6 class="card-subtitle mb-2 text-muted last-message">
          {{ contact.last_text_message_chat }}
        </h6>
        <div v-if="contact.count_unread_chat > 0" class="unread-count">
          {{ contact.count_unread_chat }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatTimestamp} from "../../../util/main";

export default {
  name: "contact",
  props: {
    contact: Object,
  },
  computed: {
    avatar() {
      return this.contact.avatar ? process.env.VUE_APP_URL + this.contact.avatar : 'profile-placeholder.jpg';
    },
    name() {
      let nameList = [];
      if (this.contact.nickname) {
        nameList.push(this.contact.nickname)
      }
      nameList.push(this.contact.name)
      nameList.push(this.contact.format_phone)
      if (this.contact.description) {
        nameList.push(this.contact.description)
      }

      return nameList.join(' | ');
    },
    lastMessageDate() {
      if (!this.contact.last_message) {
        return '13.02.2001 13:54'
      }
      return formatTimestamp(this.contact.last_message)
    },
  }
}
</script>

<style scoped>
  .contact:hover {
    width: 100%;
    background-color: whitesmoke;
  }

  .card-body {
    padding: 0 16px;
  }

  .card-title {
    font-weight: 500;
    font-size: 0.8em;
    margin-right: 16px;
    width: fit-content;
    flex-grow: 1;

    margin-bottom: 0!important;
  }

  .second-row {
    width: calc(100vw - 130px);
    gap: 32px;
    align-items: center;
  }

  .last-message {
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 0.8em;
    margin-bottom: 0!important;
  }

  .unread-count {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 30px;
    flex: 0 0 30px;
    background-color: #48bb78;
    border-radius: 50%;
    color: white;
  }
</style>
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import {router} from "@/router";
import store from "@/store";


//CSS
import './assets/css/bootstrap.min.css';
import './assets/css/tailwind.min.css';
import './assets/css/auth.css';
import './assets/css/style.css';

import {ObserveVisibility} from "vue-observe-visibility";
import VueClipboard from 'vue-clipboard2'


Vue.config.productionTip = false
Vue.prototype.$http = axios;

const token = localStorage.getItem('token')

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401 && localStorage.getItem('token')) {
    store.dispatch('refreshToken', {'refresh': localStorage.getItem('refresh')})
      .then(suss => {
        store.commit('editToken', {'token': suss.access, 'refresh': suss.refresh})
      }).catch(() => {
      store.dispatch("logout")
    })
    setTimeout(() => {
      location.reload();
    }, 40)
  }
  return Promise.reject(error);
});


new Vue({
  el: '#app',
  router: router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.directive('observe-visibility', ObserveVisibility)
Vue.use(VueClipboard)
// Vue.use(Vue2Emoji)

let filter = function (text, length, clamp) {
  clamp = clamp || '...';
  let node = document.createElement('div');
  node.innerHTML = text;
  let content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

<template>
  <div class="d-flex flex-column">
    <img :src="downloadLink" alt="Картинка" style="max-width: 100%; height: auto">
    <a style="margin-top: 16px; color: blue; font-size: 20px" :href="downloadLink" target="_blank" download>
      Скачать
    </a>
  </div>

</template>

<script>
export default {
  name: "text_message",
  props: {
    message: Object,
  },
  computed: {
    fileName() {
      return this.message.body_messages.split('/').at(-1);
    },
    downloadLink() {
      return process.env.VUE_APP_URL + this.message.body_messages;
    },
  }
}
</script>

<style scoped>

</style>
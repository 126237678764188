import axios from 'axios';

const state = {
  token: localStorage.getItem('token') || ""
}
const getters = {
  isLoggedIn(state) {
    return !!state.token
  },
  getToken(state) {
    return state.token
  }
}
const mutations = {
  editToken(state, data) {
    console.log('data', data)
    if ({}.propertyIsEnumerable.call(data, "token") && data.refresh !== null) {
      localStorage.setItem('token', data.token)
      localStorage.setItem('refresh', data.refresh)
      axios.defaults.headers.common['Authorization'] = `Bearer ` + data.token
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh')
      delete axios.defaults.headers.common['Authorization']
    }
    return state.token = data.token
  }
}
const actions = {
  refreshToken(context, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('refresh', data.refresh)

      axios({
        method: 'post',
        data: formData,
        url: process.env.VUE_APP_URL + 'api/v2/token/refresh/',
        headers: {'Content-Type': 'multipart/form-data'}
      }).catch(err => {
        if (err.response.status === 401) {
          reject(false);
        }
      }).then(suss => {
        resolve(suss.data);
      })
    })
  },
  checkToken(context, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('token', data.token)

      this.$http({
        method: 'post',
        data: formData,
        url: process.env.VUE_APP_URL + 'api/v2/token/verify/',
        headers: {'Content-Type': 'multipart/form-data'}
      }).catch(err => {
        if (err.response.status === 401) {
          reject(false);
        }
      }).then(r => {
        console.log('r', r.data)
        resolve(true);
      })
    })

  },
  login(context, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('username', data.username)
      formData.append('password', data.password)

      axios({
        method: 'post',
        url: process.env.VUE_APP_URL + 'api/v2/token/',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(response => {
        context.commit('editToken', {'token': response.data.access, 'refresh': response.data.refresh})
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  logout(context) {
    context.commit('editToken', {'token': null, 'refresh': null})
  }
}

export default {
  state, getters, mutations, actions
}

<template>
  <div>
    <contact v-for="contact in $store.getters.getChatListBase" :key="contact.id" :contact="contact" @click="$emit('openChat', contact)"/>
    <div style="margin: 32px 0; padding: 0 16px;">
      <button
        v-if="$store.getters.getPaginatorInfoChat && $store.getters.getPaginatorInfoChat.has_next"
        @click="nextPage"
        type="button" class="btn btn-outline-primary btn-lg btn-block"
      >
        Загрузить еще
      </button>
    </div>
  </div>
</template>

<script>
import contact from "@/components/chat_new/contacts/contact";

export default {
  name: "contacts_list",
  components: {contact},
  methods: {
    nextPage() {
      this.$store.dispatch('getChatsBase', {
        instance: this.$store.getters.getSelectInstance,
        status: this.$store.getters.getSelectedStatus,
        page: this.$store.getters.getPaginatorInfoChat.page + 1
      })
    },
  },
}
</script>

<style scoped>
  .btn-outline-primary:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
    background-color: white;
    color: #007bff;
  }
</style>
<template>
  <div>
    <h3>Контакатная карточка</h3>
    <br>
    Имя: {{ cardData.fn[0].value }}<br>
    Телефон: <a :href="'tel:'+cardData.tel[0].value">{{ cardData.tel[0].value }}</a>
    <template v-if="cardData.email">
      Почта: <a :href="'mailto:'+cardData.email[0].value">{{
        cardData.email[0].value
      }}</a>
    </template>
    <span v-if="cardData.url">
      <br>
      Остальной текст:<br>
      {{ cardData.url }}<br>
    </span>
  </div>
</template>

<script>
import vc from "vcard-parser";

export default {
  name: "text_message",
  props: {
    message: Object,
  },
  computed: {
    cardData() {
      return vc.parse(this.message.body_messages);
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="d-flex flex-column">
    <span style="font-weight: bold;">
      Файл: {{ fileName }}
    </span>
    <a style="margin-top: 16px; color: blue; font-size: 20px" :href="downloadLink" download>Открыть</a>
  </div>

</template>

<script>
export default {
  name: "text_message",
  props: {
    message: Object,
  },
  computed: {
    fileName() {
      return this.message.body_messages.split('/').at(-1);
    },
    downloadLink() {
      return process.env.VUE_APP_URL + this.message.body_messages;
    }
  }
}
</script>

<style scoped>

</style>
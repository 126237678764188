<template>
  <div id="main" class="d-flex flex-column" style="width: 100%; height: 100vh;">
    <settings
        :chatSelected="chatSelected"
        @exitFromChat="chatSelected = false"
    />
    <div
        ref="contentContainer"
        style="width: 100%; height: fit-content; flex-grow: 1; background-color: white;"
    >
      <contacts_list
          v-if="!chatSelected"
          v-on:openChat="(contact) => openChat($store.getters.getSelectInstance, contact.chat_id)"
      />
      <chatbox v-else @newMessageSent="scrollToBottom" />
    </div>
  </div>
</template>

<script>
import settings from "@/components/chat_new/settings";
import chatbox from "@/components/chat_new/chat/chatbox";
import contacts_list from "@/components/chat_new/contacts/contacts_list";

import axios from "axios";
// import {cssSelectRemove} from "../util/main";

export default {
  name: 'Telegram',
  components: {settings, contacts_list, chatbox},
  data() {
    return {
      loadSerch: false,
      hiddenChatArea: false,
      chatSelected: false,
    }
  },
  computed: {
    chatAreaStatus() {
      if (this.hiddenChatArea) {
        return false
      }

      if (this.$store.getters.getMessages.length > 0) {
        return true;
      } else {
        const selectChatID = this.$store.getters.getSelectChat.chat_id

        if (selectChatID) {
          if (this.$store.getters.getInfoChat(selectChatID)) {
          //   console.log('s12', this.$store.getters.getInfoChat(selectChatID).name === 'Новый чат')
          //   return this.$store.getters.getInfoChat(selectChatID).name === 'Новый чат'
          // } else {
            return true
          }
        }
      }

      return false
    }
  },
  methods: {
    scrollToBottom() {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 200)
    },
    async readAllMessagesInChat() {
      let formData = new FormData();
      formData.append('instance', this.$store.getters.getSelectChat.instance)
      formData.append('chat', this.$store.getters.getSelectChat.chat_id)
      formData.append('operator', this.$store.getters.getUserUsername);

      axios({
        method: 'post',
        url: process.env.VUE_APP_URL + 'api/v2/readAllMessages',
        data: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    openChat(instance_id, chat_id, start_number_messages) {
      let self = this
      this.hiddenChatArea = false;
      // cssSelectRemove('selectChat')
      // document.getElementById(chat_id).classList.add('selectChat')


      this.$store.commit('setChatOpening')
      this.$store.commit('resetMessage')
      this.$store.commit('setForward', {'id': undefined, 'text': undefined})
      this.$store.commit('resetChatUnreadCounter', chat_id)


      self.$store.commit('setSelectChat', {'chat_id': chat_id, 'instance': instance_id})
      self.readAllMessagesInChat()
      this.chatSelected = true;

      self.$store.dispatch('getPackMessages', {
        'chat_id': chat_id,
        'instance_id': instance_id,
        'last_number': this.$store.getters.getPaginatorInfoMessages.last_page,
        'token': localStorage.getItem('token'),
        'start_number_messages': start_number_messages,
        'ws': this.$store.getters.getCurrentWS,
      })
    },
    nextPageMessages(instance_id, chat_id) {
      this.$store.commit('editLastPage', this.$store.getters.getPaginatorInfoMessages.last_page)

      this.$store.dispatch('getPackMessages', {
        'chat_id': chat_id,
        'instance_id': instance_id,
        'last_number': this.$store.getters.getPaginatorInfoMessages.last_page,
        'ws': this.$store.getters.getCurrentWS
      })
    },
    nextPage() {
      this.$store.dispatch('getChatsBase', {
        instance: this.$store.getters.getSelectInstance,
        status: this.$store.getters.getSelectedStatus,
        page: this.$store.getters.getPaginatorInfoChat.page + 1
      })
    },
    onAlert() {
      document.addEventListener('visibilitychange', () => {
          if (!document.hidden) {
              this.$store.commit('resetChatUnreadCounter', this.$store.getters.getSelectChat.chat_id)
          }
      });
      let ws = new WebSocket(process.env.VUE_APP_WS + "/alert/" + this.$store.getters.getUserID + "/?token=" + localStorage.getItem('token'));

      let self = this;
      ws.onmessage = function (event) {
        let data = JSON.parse(event['data']);
        let type = data['type_package'];

        if (type === 'new_chat') {
          self.$store.dispatch('getChatsBase', {
            instance: self.$store.getters.getSelectInstance,
            status: self.$store.getters.getSelectedStatus,
            page: self.$store.getters.getPaginatorInfoChat.page
          })
          let audio = new Audio('/sounds/message_notify.mp3');
          audio.play()
        }

        if (type === "update_last_visit") {
          console.log('WORK 1233 32323')
          self.$store.commit('updateChatData', {
            'chat_id': data['data']['chat_id'],
            'format_last_visit': data['data']['last_visit']
          });
        }

        if (type === 'alert' && data.instance_id === self.$store.getters.getSelectInstance && !(self.$store.getters.getInfoChat(data['data']['chat_id']))) {
          setTimeout(() => {
            self.$store.dispatch('getChatsBase', {
              instance: self.$store.getters.getSelectInstance,
              status: self.$store.getters.getSelectedStatus,
              page: self.$store.getters.getPaginatorInfoChat.page
            })

          }, 1000)
        }

        if (type === 'alert' && data['data']['from_me'] === false && self.$store.getters.getSelectChat.chat_id !== data['data']['chat_id']) {
          // let offSound = JSON.parse(localStorage.getItem('offSound')) ? JSON.parse(localStorage.getItem('offSound')) : []

          if (self.$store.getters.getActiveSoundInstance.includes(data.instance_id)) {
            let audio = new Audio();
            audio.preload = 'auto';
            audio.src = '/sound/notif.mp3';
            audio.play();
          }
        }

        if (type === 'alert' && parseInt(self.$store.getters.getSelectInstance) === parseInt(data['instance_id'])) {
          if (self.$store.getters.getFakeMessages.find(chat => chat.chat_id === data.data.chat_id)) {
            console.log('data.data', data.data)
            self.$store.commit('removeFakeMessages', {
              chat_id: data.data.chat_id,
              instance_id: self.$store.getters.getSelectInstance,
              messages: data.data.body_messages,
              type_messages: data.data.type_messages
            })
          }


          console.log('updateChatData')

          self.$store.commit('updateChatData', {
            'chat_id': data.data.chat_id,
            'last_text_message_chat': data.data.body_messages,
            'last_time_update_chat': data.data.time
          });

          if (!data.data.from_me) {
            self.$store.commit('setReadChat', {'chat_id': data.data.chat_id, 'status': false});
          }
        }
      }
    },
    clickCopy(phone) {
      this.counter++;

      if (this.counter === 1) {
        this.timer = setTimeout(() => {
          this.counter = 0;
        }, 300);

        return;
      }
      if (this.counter === 2) {
        this.$copyText(phone);
      }
      clearTimeout(this.timer);
      this.counter = 0;

    },
    redirectURL(instance, chat) {
      let self = this;

      console.log('this.$route.params', this.$route.params)

      setTimeout(() => {
        let param_url = instance && chat ? {'instance': instance, 'chat': chat} : this.$route.params

        // eslint-disable-next-line no-prototype-builtins
        if (param_url.hasOwnProperty('instance') && param_url.hasOwnProperty('chat')) {
          this.$refs.tools.setInstance(param_url.instance, 'outside')

          setTimeout(() => {
            param_url.chat = param_url.chat.replace(/%20/g, "");
            param_url.chat = param_url.chat.replace(/-/g, "");
            param_url.chat = param_url.chat.replace(/\(/g, "");
            param_url.chat = param_url.chat.replace(/\)/g, "");
            param_url.chat = param_url.chat.replace(/\+/g, "");
            param_url.chat = param_url.chat.replace(/ /g, "");
            param_url.chat = 7 + param_url.chat.substr(1)

            if (self.$store.getters.getInfoChat(param_url.chat + '@c.us')) {
              this.openChat(param_url.instance, param_url.chat + '@c.us')
            } else {
              this.$store.dispatch('addChatByChatID', {
                'instance': param_url.instance,
                'chat': param_url.chat + '@c.us'
              })
              setTimeout(() => {
                this.openChat(param_url.instance, param_url.chat + '@c.us')
              }, 1500)
            }
            this.$router.push({path: '/'})

          }, 1000)
        }
      }, 1000)
    },
    updateSerch(value) {
      console.log('value', value)
      this.loadSerch = value
    }
  },
  mounted() {
    setTimeout(this.onAlert, 900);
    this.redirectURL(null, null)
  }
}
</script>

<style>
</style>

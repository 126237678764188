<template>
  <div>
    <div class="settings-placeholder" style="height: 90px;"></div>
    <div class="menu d-flex justify-content-between" style="height: 90px; position: fixed; top: 0; z-index: 1;">
      <div class="d-flex align-items-center">
        <img
            v-if="chatSelected" src="resources/arrow_left.png" alt="Назад"
            style="width: 25px; height: 25px; margin-right: 16px;"
            @click="$emit('exitFromChat')"
        >
        <p class="mb-0 self-center" style="font-size: 1em; font-weight: bold; text-align: center;">
          {{ headerText }}
        </p>
      </div>
      <button @click="showMenu = !showMenu" class="d-flex align-items-center burger-button">
        <img
            :src="showMenu ? 'resources/menu_cancel.png' : 'resources/burger.png'" alt="Меню"
            style="width: 40px; height: 40px"
        >
      </button>
    </div>


    <div v-if="showMenu" style="position: fixed; background-color: white; width: 100%; z-index: 2; height: calc(100vh - 90px); overflow-y: scroll">
      <p style="padding: 24px 16px; font-size: 18px; font-style: italic;">
        Аккаунт: сотрудник {{ $store.getters.getUserFirstName }}
      </p>
      <div style="position: absolute; width:100%; margin-top: 16px;">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a
                @click="showInstances = !showInstances" class="nav-link" href="#"
                :style="{ color: showInstances ? '#0056b3' : 'black' }"
            >
              Доступные аккаунты
            </a>
            <div v-if="showInstances" class="mx-2 my-4">
              <instance_card
                  v-for="instance in $store.getters.getUserInstance" :key="instance.id"
                  class="card mb-4" :instance="instance"
                  @exitFromChat="$emit('exitFromChat')"
              />
            </div>
          </li>
          <li class="nav-item nav-item--last">
            <a @click="logout" class="nav-link" href="#">Выйти</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import instance_card from "@/components/chat_new/instance_card";

export default {
  name: "settings",
  components: {instance_card},
  props: {
    chatSelected: Boolean,
  },
  data() {
    return {
      showMenu: false,
      showInstances: false,
    }
  },
  methods: {
    logout() {
      this.$router.push('/auth')
      this.$store.dispatch('logout')
    },
    init() {
      this.$store.dispatch('getUserInfo')
    }
  },
  computed: {
    headerText() {
      if (this.$store.getters.isChatOpening) {
        return 'Загрузка...';
      }
      if (this.chatSelected) {
        const name = this.$store.getters.getInfoChat(this.$store.getters.getPaginatorInfoMessages.chat_id).name
        if (name) {
          return name
        }
      }
      if (this.$store.getters.getUserInstanceSelect) {
        return this.$store.getters.getUserInstanceSelect.name;
      }
      return 'Инстанс не выбран'
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>
  .menu {
    width: 100%;
    padding: 24px;
    background-color: white;
    border-bottom: solid 1px darkgray;
  }

  .nav-item {
    font-size: 24px;
    border-bottom: solid 1px darkgray;
    padding: 16px 0;
    margin: 0 8px;
  }

  .nav-item--last {
    border-bottom: none!important;
  }

  .burger-button {
    flex: 0 0 auto;
    outline: none;
    margin-left: 16px;
  }
</style>